import Http from "./Http";

export default {
  async getFixtures() {
    return await Http.get(`/team/fixtures`);
  },
  async getMatch(id) {
    return await Http.get(`/team/match/${id}`);
  },
  async getMatchExtended(id) {
    return await Http.get(`/team/match/${id}/extended`);
  },
  async createRoster(id) {
    return await Http.post(`/team/match/${id}/lineups`);
  },
  async getLineups(id) {
    return await Http.get(`/team/match/${id}/lineups`);
  },
  async removeFixtures(id) {
    return await Http.delete(`/team/match/${id}`);
  },
  async addEventSubstitution(id, player_id, event, team, second_player_id) {
    return await Http.post(`/team/match/${id}/event`, {
      event: event,
      player_id: player_id,
      home_away: team ? -1 : 1, //turning 0,1 to -1 1
      second_player_id: second_player_id,
    });
  },
  async addEvent(id, player_id, event, team) {
    return await Http.post(`/team/match/${id}/event`, {
      event: event,
      player_id: player_id,
      home_away: team ? -1 : 1, //turning 0,1 to -1 1
    });
  },
  async changeStatus(id, status) {
    return await Http.post(`/team/match/${id}/changeStatus`, {
      status: status,
    });
  },
  async getTeams() {
    return await Http.get(`/team/teams`);
  },
  async addMatch(payload) {
    return await Http.post(`/team/match`, payload);
  },
  async getEvents(id) {
    return await Http.get(`/team/match/${id}/events`);
  },
  async addMOTM(id, player_id){
    return await Http.post(`/games/motm/match/${id}/end`, {
      player_id: player_id,
    });
  },

  //New Model
  async getMatches() {
    return await Http.get(`/football/matches`);
  },
  async getSportCompetitions() {
    return await Http.get(`/football/competitions`);
  },
  async getVenues() {
    return await Http.get(`/football/venues`);
  },
  async addSportMatch(payload) {
    return await Http.post(`/football/match`, payload);
  },
  async deleteSportMatch(id) {
    return await Http.delete(`/football/match/${id}`);
  },
  async getSportMatch(id) {
    return await Http.get(`/football/matches/${id}`);
  },
  async createSportRoster(id) {
    return await Http.post(`/football/match/${id}/lineups`);
  },
  async getSportMatchLineups(id) {
    return await Http.get(`/football/matches/${id}/lineups`);
  },
  async changeSportMatchStatus(id) {
    return await Http.post(`/football/match/${id}/changeStatus`);
  },
  async getSportMatchEvents(id) {
    return await Http.get(`/football/matches/${id}/events`);
  },
  async addSportMatchEvent(id, player_id, event, team, second_player_id) {
    return await Http.post(`/football/match/${id}/event`, {
      event: event,
      player_id: player_id,
      home_away: team ? -1 : 1, //turning 0,1 to -1 1
      related_player_id: second_player_id
    });
  },
};
