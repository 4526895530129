<script>
export default {
  props: {
    eventTypeId: Number, //
    eventName: String,
    eventMinute: String,
    eventPlayer: String,
    eventSecondPlayer: String,
    home_away: String,
  },
  data() {
    return {
      eventType: this.eventTypeId > 4,
    };
  },
  computed: {
    field() {
      return this.eventType ? this.eventPlayer : this.eventName;
    },
  },
};
</script>

<template>
  <div class="event-table">
    <p v-if="!eventType">{{ eventName }}</p>
    <div v-else>
      <p>{{ home_away === "home" ? field : "" }}</p>
      <p v-if="eventSecondPlayer && home_away === 'home'" class="text-sm">{{ eventSecondPlayer }}</p>
    </div>
    <div class="d-flex flex-column align-items-center">
      <svg
        v-if="!eventType"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z"
        />
      </svg>
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        v-else-if="eventTypeId === 5"
      >
        <g clip-path="url(#clip0_96_184)">
          <path
            d="M9 18C13.9706 18 18 13.9706 18 9C18 4.02944 13.9706 0 9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18Z"
            fill="black"
          />
          <path
            d="M8.72162 0.853638C6.83813 0.918718 5.03515 1.63335 3.61853 2.87632H6.12368L8.72162 1.54024V0.853638ZM9.27832 0.853638V1.54024L11.8763 2.87632H14.3814C12.9648 1.63335 11.1618 0.918718 9.27832 0.853638ZM3.04327 3.43302C2.19156 4.33595 1.55692 5.42118 1.1876 6.60621L2.67214 9.16704L4.80616 8.83302L6.75461 5.47426L5.97523 3.43302H3.04327ZM12.0247 3.43302L11.2453 5.47426L13.1938 8.83302L15.3278 9.16704L16.7938 6.62477C16.4325 5.43483 15.8041 4.34315 14.9567 3.43302H12.0247ZM7.38554 5.84539L5.51131 9.11137L7.16286 11.9691H10.8371L12.4886 9.11137L10.6144 5.84539H7.38554ZM1.00203 7.3856C0.892881 7.91686 0.836934 8.45767 0.835022 9.00003C0.836545 10.3402 1.16794 11.6595 1.79997 12.8413L2.48657 12.433L2.35667 9.7423L1.00203 7.3856ZM16.9979 7.40415L15.6433 9.7423L15.5134 12.433L16.2 12.8413C16.832 11.6595 17.1634 10.3402 17.1649 9.00003C17.1618 8.46388 17.1058 7.92934 16.9979 7.40415ZM7.16286 12.7114L5.73399 14.4743L7.16286 16.9423C7.76481 17.0859 8.38114 17.1606 8.99997 17.165C9.6188 17.1606 10.2351 17.0859 10.8371 16.9423L12.2659 14.4743L10.8371 12.7114H7.16286ZM2.72781 12.9526L2.07832 13.3237C3.0758 14.9251 4.59705 16.1316 6.38348 16.7382L5.08451 14.4557L2.72781 12.9526ZM15.2721 12.9526L12.9154 14.4557L11.6165 16.7196C13.4001 16.1178 14.9209 14.9181 15.9216 13.3237L15.2721 12.9526Z"
            fill="white"
          />
        </g>
        <defs>
          <clipPath id="clip0_96_184">
            <rect width="18" height="18" fill="white" />
          </clipPath>
        </defs>
      </svg>
      <div v-else-if="eventTypeId === 6" class="yellow-card"></div>
      <div v-else-if="eventTypeId===10"><img src="https://s3-eu-west-1.amazonaws.com/stadiaconnect-cdn/icons/substitution.png" alt=""></div>
      <div v-else class="red-card"></div>
      <p>{{ eventMinute }}</p>
    </div>
    <p v-if="!eventType">{{ eventName }}</p>
    <div v-else>
      <p>{{ home_away === "away" ? field : "" }}</p>
      <p v-if="eventSecondPlayer && home_away === 'away'" class="text-sm">{{ eventSecondPlayer }}</p>
    </div>
  </div>
</template>

<style>
p {
  margin: 0;
}
svg {
  width: 20px;
}
img{
  width: 35px;
}

.event-table {
  padding-block: 0.5rem;
  display: grid;
  grid-template-columns: repeat(3, 220px);
  gap: 1rem;
  place-content: center;
  place-items: center;
}

.event-table + .event-table {
  margin-top: 0.75rem;
}

.yellow-card {
  width: 14px;
  height: 18px;
  background-color: #ffe500;
  border-radius: 0.125rem;
  transform: rotate(15deg);
  margin-bottom: 0.25rem;
}
.red-card {
  width: 14px;
  height: 18px;
  background-color: rgb(219, 7, 0);
  border-radius: 0.125rem;
  transform: rotate(15deg);
  margin-bottom: 0.25rem;
}
.text-sm{
  font-size: 0.75rem;
}
</style>
