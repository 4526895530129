<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Swal from "sweetalert2";
import Fixtures from "../../../services/Fixtures";
import modalScoreGoal from "../../../components/modals/simulator_new/modalScoreGoal.vue";
import modalYellowCard from "../../../components/modals/simulator_new/modalYellowCard.vue";
import modalRedCard from "../../../components/modals/simulator_new/modalRedCard.vue";
import modalSubstitution from "../../../components/modals/simulator_new/modalSubstitution.vue";
import modalMOTM from "../../../components/modals/simulator_new/modalMOTM.vue";
import Events from "./components_new/Events.vue";

export default {
  components: {
    Layout,
    PageHeader,
    Events,
    modalScoreGoal,
    modalYellowCard,
    modalRedCard,
    modalSubstitution,
    modalMOTM,
  },
  page: {
    title: "Match Simulator",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      title: "Match Simulator New",
      items: [
        {
          text: "Matches",
        },
        {
          text: "Match Simulator",
        },
      ],
      eventTeam: 0, // 0 for home, 1 for away
      match_id: this.$route.params.id,
      match: null,
      canEdit: false,
      timer: { minutes: 0, seconds: 0 },
      homeSubs: [],
      awaySubs: [],
      status: null,
      phases: [
        {
          phase_id: 0,
          phase_short: "S",
          matchTime: "Match not started",
          time: "00:00",
        },
        {
          phase_id: 1,
          phase_short: "KO",
          matchTime: "Kick off",
          time: "00:01",
        },
        {
          phase_id: 2,
          phase_short: "HT",
          matchTime: "Halftime",
          time: "45:00",
        },
        {
          phase_id: 3,
          phase_short: "SHS",
          matchTime: "Second Half",
          time: "45:01",
        },
        {
          phase_id: 4,
          phase_short: "FT",
          matchTime: "Full time",
          time: "90:00",
        },
        {
          phase_id: 5,
          phase_short: "EOM",
          matchTime: "End of Match",
          time: "90:00",
        },
      ],
      events: [],
      match_status: 0,
      period: 0,
      homeTeam: {
        home_team_name: "",
        home_team_logo: "",
        score: 0,
        roster: [],
      },
      awayTeam: {
        away_team_name: "",
        away_team_logo: "",
        score: 0,
        roster: [],
      },
      rosterCreated: true,
      isBusy: false,
      error: null,
      showModal: false,
      modalData: {},
      refresh: false,
      timerStarted: false,
    };
  },
  created() {
    this.getSportMatch();
    this.getSportMatchLineups();
    this.getSportMatchEvents();
    if (this.canEdit === false) this.startTimer();
  },

  methods: {
    async getSportMatch() {
      try {
        const response = await Fixtures.getSportMatch(this.match_id);
        console.log(response);
        this.homeTeam.home_team_name = response.data.match.home_team_name;
        this.homeTeam.home_team_logo = response.data.match.home_team_logo;
        this.awayTeam.away_team_name = response.data.match.away_team_name;
        this.awayTeam.away_team_logo = response.data.match.away_team_logo;
        this.homeTeam.score = response.data.match.score_home || 0;
        this.awayTeam.score = response.data.match.score_away || 0;

        this.match = response.data.match;
        this.match_status = response.data.match.match_period_id;
        this.period = this.getStatus();
        if (this.match_status === 16) {
          this.timer.minutes = 0;
          this.timer.seconds = 0;
          this.timerStarted = false;
          return;
        }

        let time;
        if (this.match_status === 1) {
          const periodStart = new Date(
            response.data.match.period[0].period_start * 1000
          );
          const currentTime = new Date();
          const elapsedTime = currentTime - periodStart;
          this.timer.minutes = Math.floor(elapsedTime / 60000);
          this.timer.seconds = Math.floor((elapsedTime % 60000) / 1000);
          this.timerStarted = true;
        }

        if (this.match_status === 10) {
          this.timer.minutes = 45;
          this.timer.seconds = 0;
          this.timerStarted = false;
          return;
        }

        if (this.match_status === 2) {
          const periodStart = new Date(
            response.data.match.period[1].period_start * 1000
          );
          const currentTime = new Date();
          const elapsedTime = currentTime - periodStart;
          this.timer.minutes = 45 + Math.floor(elapsedTime / 60000);
          this.timer.seconds = Math.floor((elapsedTime % 60000) / 1000);
          this.timerStarted = true;
        }

        if (this.match_status === 14) {
          this.timer.minutes = 90;
          this.timer.seconds = 0;
          this.timerStarted = false;
          return;
        }
      } catch (error) {
        this.failedmsg(error.response.data);
      }
    },

    async createSportRoster() {
      try {
        this.isBusy = true;
        await Fixtures.createSportRoster(this.match_id);
        this.rosterCreated = true;
        this.isBusy = false;
        this.getSportMatchLineups();
      } catch (error) {
        this.failedmsg(error.response.data.error);
        this.isBusy = false;
      }
    },

    async getSportMatchLineups() {
      try {
        const response = await Fixtures.getSportMatchLineups(this.match_id);
        const substitutionEvents = this.events.filter(
          (ev) => ev.event_type_name === "Substitution"
        );

        const substitutedOutPlayerIds = new Set(
          substitutionEvents.map((ev) => ev.related_player_id)
        );
        const substitutedInPlayerIds = new Set(
          substitutionEvents.map((ev) => ev.player_id)
        );

        console.log("Response data:", response.data);
        console.log("Substituted Out Player IDs:", substitutedOutPlayerIds);
        console.log("Substituted In Player IDs:", substitutedInPlayerIds);

        if (
          response.data.lineups.home_team_starters.length === 0 &&
          response.data.lineups.away_team_starters.length === 0
        ) {
          this.rosterCreated = false;
          return;
        }

        this.homeTeam.roster = [
          ...response.data.lineups.home_team_starters.filter(
            (e) => !substitutedOutPlayerIds.has(e.player_id)
          ),
          ...response.data.lineups.home_team_subs.filter((e) =>
            substitutedInPlayerIds.has(e.player_id)
          ),
        ];

        this.awayTeam.roster = [
          ...response.data.lineups.away_team_starters.filter(
            (e) => !substitutedOutPlayerIds.has(e.player_id)
          ),
          ...response.data.lineups.away_team_subs.filter((e) =>
            substitutedInPlayerIds.has(e.player_id)
          ),
        ];

        this.homeSubs = [
          ...response.data.lineups.home_team_subs.filter(
            (player) => !substitutedInPlayerIds.has(player.player_id)
          ),
          ...response.data.lineups.home_team_starters.filter((player) =>
            substitutedOutPlayerIds.has(player.player_id)
          ),
        ];

        this.awaySubs = [
          ...response.data.lineups.away_team_subs.filter(
            (player) => !substitutedInPlayerIds.has(player.player_id)
          ),
          ...response.data.lineups.away_team_starters.filter((player) =>
            substitutedOutPlayerIds.has(player.player_id)
          ),
        ];

        this.rosterCreated = true;
      } catch (error) {
        this.failedmsg(error.response.data.message);
      }
    },

    async getSportMatchEvents() {
      try {
        const response = await Fixtures.getSportMatchEvents(this.match_id);
        this.events = response.data.events;
        this.refresh = !this.refresh;
      } catch (error) {
        if (error.response.data.error === "No match events") return;
        this.failedmsg(error.response.data.error);
      }
    },

    async changeSportMatchStatus() {
      try {
        const response = await Fixtures.changeSportMatchStatus(this.match_id);
        this.match_status = response.data.match_period_id;
        this.getSportMatch();
      } catch (error) {
        this.failedmsg(error.response.data.error);
      }
    },

    handleResponse(teamId) {
      console.log("Received response from modalYellowCard or red:", teamId);
      this.getSportMatchLineups();
      this.getSportMatchEvents();
    },
    startTimer() {
      if (this.canEdit) {
        setTimeout(() => {
          if (this.timer.seconds > 58) {
            this.timer.minutes += 1;
            this.timer.seconds = -1;
            if (this.timer.minutes === 45 || this.timer.minutes === 90) {
              this.timer.seconds = 0;
              this.changeStatus();
              return;
            }
          }
          this.timer.seconds++;
          this.startTimer();
        }, 1000);
      }
    },
    score() {
      this.getSportMatchEvents();
      this.getSportMatch();
    },

    modalScoreGoal(team) {
      this.eventTeam = team;
      this.$bvModal.show("score_goal");
    },

    modalYellowCard(team) {
      this.eventTeam = team;
      this.$bvModal.show("yellow_card");
    },

    modalRedCard(team) {
      this.eventTeam = team;
      this.$bvModal.show("red_card");
    },

    modalSubstitution(team) {
      this.eventTeam = team;
      this.$bvModal.show("substitution");
    },

    modalMOTM() {
      this.$bvModal.show("motm");
    },
    getStatus() {
      if (!this.match || !this.match.period || this.match.period.length === 0) {
        return 0;
      }

      const periods = this.match.period;

      if (periods.length > 0) {
        const firstHalf = periods.find((period) => period.period_id === 1);
        const secondHalf = periods.find((period) => period.period_id === 2);

        if (
          firstHalf &&
          firstHalf.period_start &&
          firstHalf.period_end == null
        ) {
          return 1; // First half in progress
        }

        if (
          firstHalf &&
          firstHalf.period_end &&
          (!secondHalf || secondHalf.period_start == null)
        ) {
          return 2; // Halftime
        }

        if (
          secondHalf &&
          secondHalf.period_start &&
          secondHalf.period_end == null
        ) {
          return 3; // Second half in progress
        }

        if (secondHalf && secondHalf.period_end) {
          return 4; // Full time
        }
      }

      return 0; // Default case, match not started or unknown period
    },

    failedmsg(msg) {
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Oops...",
        text: msg,
        showConfirmButton: false,
        timer: 3000,
      });
    },
  },
  watch: {
    canEdit(e) {
      e && this.startTimer();
    },
    match_status(s) {
      if (s === 16 || s === 10 || s === 14) {
        this.canEdit = false;
      } else this.canEdit = true;
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div
      class="d-flex gap-2 justify-content-center container bg-white mw-75 mb-4 py-2 shadow rounded"
    >
      <button
        class="btn btn-success w-100"
        @click="changeSportMatchStatus"
        :disabled="match_status === 14 || !rosterCreated"
      >
        {{ phases[period + 1].matchTime }}
      </button>
      <button
        class="btn btn-primary w-100"
        v-if="match_status === 14"
        @click="modalMOTM()"
      >
        <!-- {{ isBusy ? "Creating..." : "Create Roster" }} -->
        {{ "Man Of The Match" }}
      </button>
      <button
        v-else
        class="btn btn-primary w-100"
        @click="createSportRoster"
        :disabled="rosterCreated"
      >
        {{ isBusy ? "Creating..." : "Create Roster" }}
      </button>
    </div>
    <div
      class="container bg-white mw-75 d-flex justify-content-center rounded p-4 shadow"
    >
      <div class="d-flex align-items-baseline">
        <div class="text-center logo-cover">
          <img
            :src="homeTeam.home_team_logo"
            :alt="homeTeam.home_team_name"
            class="rounded-circle w-50"
          />
          <p>{{ homeTeam.home_team_name }}</p>
        </div>
        <div class="text-center d-flex flex-column align-items-center">
          <div class="d-flex gap-4 align-items-end">
            <h3>{{ homeTeam.score }}</h3>
            <h3>-</h3>
            <h3>{{ awayTeam.score }}</h3>
          </div>
          <p class="mb-1 d-flex">
            <span>
              {{
                timer.minutes.toLocaleString("en-US", {
                  minimumIntegerDigits: 2,
                  useGrouping: false,
                })
              }}
            </span>
            :
            <span>
              {{
                timer.seconds.toLocaleString("en-US", {
                  minimumIntegerDigits: 2,
                  useGrouping: false,
                })
              }}
            </span>
          </p>
          <p>{{ match.match_period_name }}</p>
        </div>
        <div class="text-center logo-cover">
          <img
            :src="awayTeam.away_team_logo"
            :alt="awayTeam.away_team_name"
            class="rounded-circle w-50"
          />
          <p>{{ awayTeam.away_team_name }}</p>
        </div>
      </div>
    </div>
    <div
      class="d-flex container bg-white shadow mw-75 justify-content-around mt-4 py-2 rounded gap-2"
    >
      <div class="w-100 px- d-flex flex-column gap-2">
        <button
          :disabled="!rosterCreated || !canEdit"
          id="show-btn"
          @click="modalScoreGoal(0)"
          class="btn-success btn w-100"
        >
          Score
        </button>
        <button
          :disabled="!rosterCreated || !canEdit"
          @click="modalYellowCard(0)"
          class="btn-warning btn w-100"
        >
          Yellow Card
        </button>
        <button
          :disabled="!rosterCreated || !canEdit"
          @click="modalRedCard(0)"
          class="btn-danger btn w-100"
        >
          Red Card
        </button>
        <button
          :disabled="!rosterCreated || !canEdit"
          @click="modalSubstitution(0)"
          class="btn-secondary btn w-100"
        >
          Substitution
        </button>
      </div>
      <div class="d-flex" style="height: inherit;">
        <div class="vr"></div>
      </div>
      <div class="w-100 px- d-flex flex-column gap-2">
        <button
          :disabled="!rosterCreated || !canEdit"
          @click="modalScoreGoal(1)"
          class="btn-success btn w-100"
        >
          Score
        </button>
        <button
          :disabled="!rosterCreated || !canEdit"
          @click="modalYellowCard(1)"
          class="btn-warning btn w-100"
        >
          Yellow Card
        </button>
        <button
          :disabled="!rosterCreated || !canEdit"
          @click="modalRedCard(1)"
          class="btn-danger btn w-100"
        >
          Red Card
        </button>
        <button
          :disabled="!rosterCreated || !canEdit"
          @click="modalSubstitution(1)"
          class="btn-secondary btn w-100"
        >
          Substitution
        </button>
      </div>
    </div>
    <Events :events="events" :key="refresh" />
    <modalScoreGoal
      :team="eventTeam"
      :lineupHome="homeTeam.roster"
      :lineupAway="awayTeam.roster"
      :match_id="match_id"
      @response="score"
    ></modalScoreGoal>
    <modalYellowCard
      :team="eventTeam"
      :lineupHome="homeTeam.roster"
      :lineupAway="awayTeam.roster"
      :match_id="match_id"
      @response="handleResponse"
    ></modalYellowCard>
    <modalRedCard
      :team="eventTeam"
      :lineupHome="homeTeam.roster"
      :lineupAway="awayTeam.roster"
      :match_id="match_id"
      @response="handleResponse"
    >
    </modalRedCard>
    <modalSubstitution
      :team="eventTeam"
      :lineupHome="homeTeam.roster"
      :homeSubs="homeSubs"
      :awaySubs="awaySubs"
      :lineupAway="awayTeam.roster"
      :match_id="match_id"
      @response="handleResponse"
    >
    </modalSubstitution>
    <modalMOTM
      :lineupHome="homeTeam.roster"
      :lineupAway="awayTeam.roster"
      :match_id="match_id"
      @response="handleResponse"
    >
    </modalMOTM>
  </Layout>
</template>

<style>
p {
  margin: 0;
}
.logo-cover {
  width: 200px;
}
.loading {
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
  color: transparent;
  background-color: grey;
}
</style>
